import { ONBOARDING_URLS, CUSTOMER_APP_URLS } from "./constants/routes";

type Method = "POST" | "GET" | "PUT" | "PATCH" | "DELETE";

export const api = async <TData, TResponse>(
  url: RequestInfo,
  method: Method = "GET",
  data: TData = {} as TData,
  headers?: {}
): Promise<TResponse> => {
  const options: RequestInit = {
    method,
    headers: {
      "Content-Type": "application/json",
      ...headers,
    },
    credentials: "include",
  };

  if (data && method !== "GET") {
    options.body = JSON.stringify(data);
  }
  let res: Response | null = null;
  try {
    res = await fetch(url, options);
    const response = await res.text();
    if (response) {
      return JSON.parse(response);
    } else {
      try {
        let data = await res.text();
        console.warn("API NOT RETURNING DATA::", url, data, res);
      } catch (error) {
        console.warn("API ERROR::", url, error, res);
      }
      return {} as TResponse;
    }
  } catch (error) {
    console.log("API ERROR::", url, error, res);
  }

  return {} as TResponse;
};

export const GetBaseUrl = (environment?: string) => {
  const env = environment || process.env.ENVIRONMENT;
  if (env == "production") {
    return ONBOARDING_URLS.production;
  } else if (env == "staging") {
    return ONBOARDING_URLS.staging;
  } else {
    return ONBOARDING_URLS.development;
  }
};

export const GetBaseCustomerAppURL = (environment?: string) => {
  const env = environment || process.env.ENVIRONMENT;
  if (env == "production") {
    return CUSTOMER_APP_URLS.production;
  } else if (env == "staging") {
    return CUSTOMER_APP_URLS.staging;
  } else {
    return CUSTOMER_APP_URLS.development;
  }
};
